@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';

.accordion {
  $breakpoint: 500px;

  &__toggle {
    display: none;
    padding-top: spacing.$s;

    &--toggle {
      display: block;
    }

    @media (min-width: $breakpoint) {
      display: block;

      &--toggle {
        display: none;
      }
    }
  }

  &__splitter {
    margin-top: spacing.$m;
    margin-bottom: spacing.$s;
    border-bottom: 1px solid lightmode.$ui-border;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 spacing.$m;
  }

  &__icon {
    display: flex;
    gap: spacing.$xs;
    align-items: center;
  }

  &__button-wrapper {
    display: flex;
  }

  &__button-desktop {
    display: none;

    @media (min-width: $breakpoint) {
      display: block;
    }
  }

  &__button-mobile {
    display: block;

    @media (min-width: $breakpoint) {
      display: none;
    }
  }

  &__header-description {
    color: lightmode.$signal-warning-text;
  }

  &__times {
    display: flex;
    flex-direction: column;
    gap: spacing.$xxs;
    padding: 0 spacing.$m;
  }

  &__time {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: spacing.$xxs 0;
  }
}
